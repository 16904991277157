import { css, Global } from '@emotion/core'
import { withTheme } from 'emotion-theming'
import * as React from 'react'
import BluePhone from '../../../../sites/attsavings/src/images/blue-phone.svg'

const HeaderStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-header {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        border-bottom: 1px solid #f2f2f2 !important;

        &.leshen-header-desktop {
          nav {
            flex-wrap: wrap;

            > a {
              display: block;
              margin-bottom: 12px;
            }

            .subnav {
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }

          .primary-link {
            a {
              padding: 8px 12px;
            }

            &:first-of-type {
              margin-left: 0;
            }

            &:hover {
              background-color: #f2f2f2;
              color: #1d2329;
            }
          }
        }

        /* Header Desktop CTA */
        .left-cta {
          margin-right: 8px;

          a {
            display: flex;
            gap: 8px;
            margin-left: auto;
            text-decoration: none;
            color: #0057b8;
            padding-right: 8px;

            img {
              width: 22px;
              height: 22px;
            }

            .dark {
              color: #1d2329;
            }
          }

          button {
            padding: 0;

            .button-text {
              font-size: 18px;
              padding: 10px 16px;
              display: flex;
              align-items: center;

              img {
                margin-right: 10px;
              }
            }
          }
        }

        /* Right CTA */
        .phone-link {
          display: block;
          font-weight: 400;
          font-size: 20px;

          &:hover {
            text-decoration: none;
          }
        }

        img[alt='logo'] {
          display: block;
          margin-right: auto;
          height: 48px;
        }
      }

      ul li button {
        &:active {
          background-color: unset !important;
          color: unset !important;
        }
      }

      .leshen-header-mobile {
        .menu-button svg path {
          fill: #102641;
        }
      }

      .top-bar {
        .header-link:last-of-type {
          border-left-color: #e5e5e5 !important;
        }
      }

      .bottom-bar {
        border-bottom: 1px solid #f2f2f2 !important;
        padding-top: 16px !important;
        padding-bottom: 16px !important;

        > a:first-of-type {
          margin-right: auto;
        }

        .phone-link {
          border: 1px solid #0057b8;
          border-radius: 2em;
          padding: 8px 16px;
          display: flex;
          align-items: center;
          font-size: 18px;

          &::before {
            content: '';
            background-image: url(${BluePhone});
            display: block;
            width: 22px;
            height: 22px;
            background-size: cover;
            margin-right: 10px;
          }
        }
      }
    `}
  />
))

export default HeaderStyles
