import { withTheme } from 'emotion-theming'
import React from 'react'

import FooterStyles from '../../../../../gatsby-theme-att/src/components/CustomStyles/Footer.styles'
import GlobalCss from '../../../../../gatsby-theme-att/src/components/CustomStyles/GlobalCss.styles'
import HeaderStyles from '../../../../../gatsby-theme-att/src/components/CustomStyles/Header.styles'
import CtaBar from './CtaBar.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
    <HeaderStyles />
    <CtaBar />
    <FooterStyles />
  </>
))

export default GlobalStyles
